<template>
	<div class="flex-1 bg-white pa-24 radius-20">

		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">게시판 관리</h3>
		</div>
		<!-- 커버 -->
		<div class="mt-10 text-center">
			<h5 class="text-left">기본 게시판</h5>
			<div class="prl-20">
				<ul class="mt-20">
					<li class="ptb-14 color-333">
						카르텔 스토리
					</li>
					<li class="ptb-14 color-333 flex-row justify-space-between">
						공지사항
						<p class="toggle-group h_unset" @click="postBoardSetting(notice_info)">
							<!-- TODO : 수신 허용시 .on 클래스 추가 -->
							<span class="btn-toggle " :class="{ on: notice_info.board_state_code == 'CA00800002'}"><em class="hide">{{  notice_info.board_name }}</em></span>
						</p>
					</li>
					<li class="ptb-14 color-333 flex-row justify-space-between">
						한줄응원
						<p class="toggle-group h_unset" @click="postBoardSetting(online_info)">
							<!-- TODO : 수신 허용시 .on 클래스 추가 -->
							<span class="btn-toggle " :class="{ on: online_info.board_state_code == 'CA00800002'}"><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
						</p>
					</li>
				</ul>
			</div>

			<hr class="under-line mt-20">

			<div class="mt-10">
				<h5 class="text-left">구독 게시판</h5>
				<ul
					v-if="items_subscribe_list.length > 0"
				>
					<draggable
						v-model="items_board_config"
						@start="setAction"
						@end="endAction"
						handle=".handle"
						:options = "{animation:150}"
					>
						<template
							v-for="(item, index) in items_subscribe_list"
						>
							<li
								v-if="item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'"
								:key="'item_' + index"
								class="prl-20 flex-row justify-space-between items-center handle"
								:class="{ 'bg-gray-light': is_action === index}"
							>
								<span class="color-333 pt-13 pb-13">{{  item.board_name  }}</span>
								<div
									v-if="type == 'modify'"
								>
									<button
										@click="onConfirm(item)"
										class="btn-inline btn-primary-outline radius-20 pa-5-15 mr-10 size-px-12 font-weight-500"
									>삭제</button>
									<button
										@click="onModifySubscribe(item)"
										class="btn-inline btn-primary-outline radius-20 pa-5-15 mr-10 size-px-12 font-weight-500"
									>수정</button>

									<v-icon class="cursor-move color-dadada">mdi-reorder-horizontal</v-icon>										<!-- 순서이동 액션탭 -->
								</div>
							</li>
						</template>
					</draggable>
				</ul>

				<Empty
					v-else
					type="board_config"
				></Empty>
			</div>

			<hr class="under-line mt-10">

			<div class="mt-10">
				<h5 class="text-left">일반 게시판</h5>
				<ul
					v-if="items_list.length > 0"
				>
					<draggable
						v-model="items_board_config"
						@start="setAction"
						@end="endAction"
						handle=".handle"
						:options = "{animation:150}"
					>
						<template
							v-for="(item, index) in items_list"
						>
							<li
								v-if="item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'"
								:key="'item_' + index"
								class="prl-20 flex-row justify-space-between items-center handle"
								:class="{ 'bg-gray-light': is_action === index}"
							>
								<span class="color-333 pt-13 pb-13">{{  item.board_name  }}</span>
								<div
									v-if="type == 'modify'"
								>
									<button
										@click="onConfirm(item)"
										class="btn-inline btn-primary-outline radius-20 pa-5-15 mr-10 size-px-12 font-weight-500"
									>삭제</button>
									<button
										@click="onModify(item)"
										class="btn-inline btn-primary-outline radius-20 pa-5-15 mr-10 size-px-12 font-weight-500"
									>수정</button>

									<v-icon class="cursor-move color-dadada">mdi-reorder-horizontal</v-icon>										<!-- 순서이동 액션탭 -->
								</div>
							</li>
						</template>
					</draggable>
				</ul>

				<Empty
					v-else
					type="board_config"
				></Empty>
			</div>

			<hr class="under-line mt-30">

			<div
				class="mt-30 flex-row justify-space-between"
			>
				<div>
					<button
						@click="on125"
						class="btn-inline btn-primary-outline radius-20 mr-10"
					><v-icon class="color-primary size-px-20">mdi-plus</v-icon>게시판 추가</button>

					<button
						@click="onSubscribe"
						class="btn-inline btn-primary-outline radius-20 "
					><v-icon class="color-primary size-px-20">mdi-plus</v-icon>구독 게시판 추가</button>
				</div>

				<button
					class="btn-inline btn-primary radius-20 pa-10-20"
					@click="postBoardSort"
					:disabled="is_save"
				>저장</button>

			</div>
		</div>

		<PopupConfirm
			v-if="is_confirm"
			@click="deleteBoard(true)"
			@cancel="is_confirm = false"
			class="text-center"
		>
			<template v-slot:title>게시판 삭제</template>
			<template
				v-slot:main-txt
			>게시판 삭제 시 등록된 글도 삭제가 됩니다.<br/>게시판을 삭제하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>삭제를 원하시면 삭제버튼을 눌러주세요.</template>
		</PopupConfirm>

		<mafia125
			v-if="is_125"
			:user="user"
			:board="item_confirm"

			@cancel="is_125 = false"
			@click="off125"

		></mafia125>

		<SubscribeSetting
			v-if="is_on_subscribe_setting"
			:user="user"
			:item="item_confirm"

			@click="offSubscribe"
			@cancel="is_on_subscribe_setting = false"
		></SubscribeSetting>

		<PopupConfirm
			v-if="is_on_subscribe"
			:not_cancel="true"

			@click="toSubscribe"
			@cancel="is_on_subscribe = false"
		>
			<template v-slot:title>{{  $language.subscribe.title_subscribe_add }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.subscribe.txt_subscribe_add_main }}</template>
		</PopupConfirm>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import PopupConfirm from '@/view/Layout/PopupConfirm'
import Mafia125 from "@/view/Cartel/mafia125";
import Empty from "@/view/Layout/Empty";
import SubscribeSetting from "@/view/Subscribe/SubscribeSetting";
export default {
	name: 'mafia122'
	, props: ['user']
	, components: {SubscribeSetting, Empty, Mafia125, draggable, PopupConfirm}
	, data: function(){
		return {
			program: {
				name: '게시판 관리'
				, title: '게시판 관리'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, from: this.$route.params.from
				, bg_contents: 'bg-gray01'
				, is_side: true
			}
			, item_cartel: {}
			, items_board_config: []
			, type: 'modify'
			, is_action: ''
			, is_move: false
			, is_confirm: false
			, item_confirm: {}
			, item_search: {
				page_number: this.$language.base.page_number
				, pagerecnum: 30
			}
			, is_125: false
			, is_on_subscribe: false
			, is_on_subscribe_setting: false
		}
	}
	, computed: {
		text_type_btn: function(){
			let t = ''
			switch (this.type){
				case 'view':
					t = '수정'
					break
				case 'modify':
					t = '완료'
					break
			}
			return t
		}
		, board_list: function(){
			let t = [];
			let i = 1;
			this.items_board_config.filter((item) => {
				if(item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'){
					t.push({
						board_number: item.board_number
						, board_name: item.board_name
						, sort_seqen: i
					})
					i++
				}
			})

			return t
		}
		, is_save: function(){
			let t = true
			if(this.is_move){
				t = false
			}
			return t
		}

		, notice_info: function (){
			let t = {}

			this.items_board_config.filter((item) => {
				if(item.board_type_code == 'CA00700001'){
					t = item
				}
			})

			return t
		}
		, online_info: function (){
			let t = {}

			this.items_board_config.filter((item) => {
				if(item.board_type_code == 'CA00700002'){
					t = item
				}
			})

			return t
		}
		, story_info: function (){
			let t = {}

			this.items_board_config.filter((item) => {
				if(item.board_type_code == 'CA00700004'){
					t = item
				}
			})

			return t
		}
		, items_list: function(){
			let t = []
			this.items_board_config.filter( (item) => {
				if(item.board_type_code == 'CA00700003'){
					t.push(item)
				}
			})

			return t
		}
		, items_subscribe_list: function(){

			let t = []
			this.items_board_config.filter( (item) => {
				if(item.board_type_code == 'CA00700005'){
					t.push(item)
				}
			})

			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					this.items_board_config = result.data.board_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){
			//
		}
		, doType: function(){
			switch (this.type){
				case 'view':
					this.type = 'modify'
					this.$router.push('/cartel/mafia122/' + this.$route.params.idx + '/modify')
					break
				case 'modify':
					this.postBoardSort()
					break
			}
		}
		, goBack: function(){
			switch (this.type){
				case 'view':
					this.$emit('to', { name: 'mafia074', params: {idx: this.$route.params.idx}})
					break
				case 'modify':
					this.type = 'view'
					break
			}
		}
		, onModify: function(item){
			this.item_confirm = item
			this.is_125 = true
		}
		, onConfirm: function(item){
			this.item_confirm = item
			this.is_confirm = true
		}
		, deleteBoard: async function(){
			try{
				this.$bus.$emit('on', true)

				let url = this.$api_url.api_path.post_board_config_delete
				if(this.item_confirm.board_type_code == 'CA00700005'){
					url = this.$api_url.api_path.post_subscribe_delete
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item_confirm.board_number
					}
					, type: true
				})
				if(result.success){
					await this.getBoardConfig()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, setAction: function ($e){
			this.$log.console('e', $e)
			this.is_action = $e.oldIndex
		}
		, endAction: function(){
			this.is_action = ''
			this.is_move = true
		}
		, postBoardSort: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_board_sort
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_list: this.board_list
					}
					, type: true
				})
				if(result.success){
					await this.getBoardConfig()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
				this.is_move = false
			}
		}
		, on125: function(){
			this.item_confirm = ''
			this.is_125 = true
		}
		, off125: function(){
			this.item_confirm = ''
			this.is_125 = false
			this.getBoardConfig()
		}
		, postBoardSetting: async function(item){

			if(item.board_state_code == 'CA00800001' || item.board_state_code == 'CA00800002'){
				this.$set(item, 'board_state_code', 'CA00800003')
			}else{
				this.$set(item, 'board_state_code', 'CA00800002')
			}

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_board_setting
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, board_type_code: item.board_type_code
						, board_state_code: item.board_state_code
					}
					, type: true
				})
				if(result.success){
					//
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, onModifySubscribe: function(item){
			this.item_confirm = item
			this.is_on_subscribe_setting = true
		}

		, onSubscribe: function(){
			this.is_on_subscribe = true
		}
		, toSubscribe: function(){
			this.is_on_subscribe = false
			this.is_on_subscribe_setting = true
		}
		, offSubscribe: function(){
			this.is_on_subscribe = false
			this.is_on_subscribe_setting = false
			this.getBoardConfig()
		}
	}
	, async created() {
		await this.getCartel()
		await this.getBoardConfig()
		await this.$bus.$emit('onLoad', this.program)
	}
}
</script>

